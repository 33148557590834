import React from "react";
import Login2 from "../../Components/Login2/Login2";
import "./Login.scss";

const Login = () => {
  return (
    <div className="login-register-container">
      <Login2 type="login" />
    </div>
  );
};

export default Login;
