import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../../i18n/i18n";
import "./Lng.scss";

const Lng = () => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const { t } = useTranslation();

  const [hamburger, setHamburger] = useState(false);

  const hamburgerMenu = () => {
    setHamburger(!hamburger);
  };

  return (
    <div className="lng dropdown">
      <button class="dropbtn">
        <img src={t("lng")} alt="idioma" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="m11.3 14.3l-2.6-2.6q-.475-.475-.212-1.087T9.425 10h5.15q.675 0 .938.613T15.3 11.7l-2.6 2.6q-.15.15-.325.225T12 14.6q-.2 0-.375-.075T11.3 14.3Z" />
        </svg>
      </button>
      <div className="dropdown-content">
        <Link
          aria-label="lng"
          onClick={() => {
            changeLanguage("va");
            hamburgerMenu();
          }}
        >
          <img src="/assets/banderaval.png" alt="Opción 1" />
          Va
        </Link>
        <Link
          aria-label="lng"
          onClick={() => {
            changeLanguage("es");
            hamburgerMenu();
          }}
        >
          <img src="/assets/banderaes.png" alt="Opción 2" />
          Es
        </Link>
        <Link
          aria-label="lng"
          onClick={() => {
            changeLanguage("en");
            hamburgerMenu();
          }}
        >
          <img src="/assets/banderaen.jpg" alt="Opción 3" />
          En
        </Link>
        <Link
          aria-label="lng"
          onClick={() => {
            changeLanguage("it");
            hamburgerMenu();
          }}
        >
          <img src="/assets/banderait.png" alt="Opción 3" />
          It
        </Link>
      </div>
    </div>
  );
};

export default Lng;
