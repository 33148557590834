import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import pdf from "./CV_FERRAN CARRILLO CHINER.pdf";
import "./Footer.scss";
const SocialIcon = ({ platform, link, icon, viewBox }) => (
  <a href={link} rel="noreferrer" target="_blank" aria-label="social">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox={viewBox}
    >
      <path d={icon} />
    </svg>
  </a>
);

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="footer--top">
        <div className="footer--top__name">
          <div className="name">
            <h1>Ferran Chiner</h1>
            <a
              href={pdf}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="cv"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  stroke="#ffffff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                >
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2z" />
                  <path d="M11 12.5a1.5 1.5 0 0 0-3 0v3a1.5 1.5 0 0 0 3 0m2-4.5l1.5 6l1.5-6" />
                </g>
              </svg>
            </a>
          </div>
          <p>{t("Footerp")}</p>
        </div>
        <div className="footer--top__social">
          <div className="footer--top__social__icons">
            <h1>SOCIAL</h1>
            <div className="footer--top__social__icons__svg">
              <SocialIcon
                platform="TikTok"
                link="https://tiktok.com/@mestrecabreta"
                icon="M21 7.917v4.034a9.948 9.948 0 0 1 -5 -1.951v4.5a6.5 6.5 0 1 1 -8 -6.326v4.326a2.5 2.5 0 1 0 4 2v-11.5h4.083a6.005 6.005 0 0 0 4.917 4.917z"
                viewBox="0 0 24 24"
              />
              <SocialIcon
                platform="Instagram"
                link="https://instagram.com/mestrecabreta"
                icon="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
                viewBox="0 0 24 24"
              />
              <SocialIcon
                platform="YouTube"
                link="https://youtube.com/@mestrecabreta"
                icon="M234.33 69.52a24 24 0 0 0-14.49-16.4C185.56 39.88 131 40 128 40s-57.56-.12-91.84 13.12a24 24 0 0 0-14.49 16.4C19.08 79.5 16 97.74 16 128s3.08 48.5 5.67 58.48a24 24 0 0 0 14.49 16.41C69 215.56 120.4 216 127.34 216h1.32c6.94 0 58.37-.44 91.18-13.11a24 24 0 0 0 14.49-16.41c2.59-10 5.67-28.22 5.67-58.48s-3.08-48.5-5.67-58.48Zm-72.11 61.81l-48 32A4 4 0 0 1 108 160V96a4 4 0 0 1 6.22-3.33l48 32a4 4 0 0 1 0 6.66Z"
                viewBox="0 0 256 256"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footer--links">
        <Link to="/terms-of-services">Terms or Services</Link>
        <Link to="/privacy-notice">Privacy Notice</Link>
      </div>
      <div className="line"></div>
      <div className="footer--botton">
        <p>
          © Copyright 2024 . Made by{" "}
          <a
            href="https://www.javierazagra.com"
            rel="noreferrer"
            target="_blank"
          >
            Javier Azagra.
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
