import React from "react";
import { useSelector } from "react-redux";
import DatosPersonales from "../../Components/DatosPersonales/DatosPersonales";
import Login from "../../Pages/Login/Login";
import "./Profile.scss";

const Profile = () => {
  const { user, token } = useSelector((state) => state.auth);
  return (
    <>
      <div className="profile">
        {!user && <Login />}
        {user && <DatosPersonales />}
      </div>
    </>
  );
};

export default Profile;
