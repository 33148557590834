import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  editProfile,
  getProfiles,
  newProfile,
} from "../../Redux/profiles/profiles.action";
import Loader from "../Loader/Loader";
import "./DatosPersonales.scss";

const DatosPersonales = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm();

  const [edit, setEdit] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { profiles, isLoading, error } = useSelector((state) => state.profiles);

  useEffect(() => {
    dispatch(getProfiles());
  }, []);

  useEffect(() => {
    reset(profiles[0]);
  }, [profiles]);

  const today = new Date().toISOString().split("T")[0];
  const [maxDate, setMaxDate] = useState(today);

  const createNewProfile = (data) => {
    const formData = new FormData();
    formData.append("img", data.img[0]);
    formData.append("genre", data.genre);
    formData.append("name", data.name);
    formData.append("surname", data.surname);
    formData.append("birthDate", data.birthDate);
    dispatch(newProfile(formData));
  };

  const editProfileData = (data) => {
    console.log(data);
    const formData = new FormData();
    formData.append("img", data.img[0]);
    formData.append("genre", data.genre);
    formData.append("name", data.name);
    formData.append("surname", data.surname);
    formData.append("birthDate", data.birthDate);
    dispatch(editProfile(formData));
    setEdit(false);
  };

  return (
    <div className="personalData__box">
      {isLoading && <Loader />}
      {!isLoading && profiles.length <= 0 && (
        <div className="personalData">
          <form
            className="personalData__form"
            onSubmit={handleSubmit(createNewProfile)}
          >
            <img src="/assets/homeimg.jpg" alt="home" />
            <h2>{t("personalDataTitle")}</h2>
            <div>
              <label htmlFor="img" className="primary_button">
                <p className="fakeButton">{t("profileImg")}</p>
              </label>
              <input
                type="file"
                name="photo"
                id="img"
                placeholder="Imagen de perfil"
                accept=".png, .jpg, .jpeg"
                {...register("img", { required: true })}
              />
            </div>
            <div>
              <label>
                {t("genre")}
                <select
                  name="genre"
                  id="genre"
                  {...register("genre", {
                    required: true,
                  })}
                >
                  <option disabled selected value>
                    {t("option")}
                  </option>
                  <option id="genre">female</option>
                  <option id="genre">male</option>
                  <option id="genre">undefined</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                {t("birthdate")}
                <input
                  type="date"
                  name="birthDate"
                  id="birthDate"
                  max={maxDate}
                  {...register("birthDate")}
                />
              </label>
            </div>
            <div>
              <label>
                {t("name")}
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Nombre"
                  {...register("name", { required: true })}
                />
              </label>
            </div>
            <div>
              <label>
                {t("surname")}
                <input
                  type="text"
                  name="surname"
                  id="surname"
                  placeholder="Apellido"
                  {...register("surname", { required: true })}
                />
              </label>
            </div>

            <button className="primary_button" type="submit">
              {t("send")}
            </button>
          </form>
        </div>
      )}
      {profiles.length > 0 && !edit && (
        <>
          {profiles.map((profile) => {
            // Función para convertir la imagen a una cadena base64
            const imageToBase64 = (url, callback) => {
              const img = new Image();
              img.crossOrigin = "Anonymous";
              img.onload = function () {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.height = this.height;
                canvas.width = this.width;
                ctx.drawImage(this, 0, 0);
                const dataURL = canvas.toDataURL();
                callback(dataURL);
              };
              img.src = url;
            };

            // Guardar la imagen en el localStorage
            const saveImageToLocalStorage = (imageUrl) => {
              localStorage.setItem("profileImage", imageUrl);
            };

            // Llamar a la función para guardar la imagen en el localStorage
            imageToBase64(profile.img, saveImageToLocalStorage);

            return (
              <div className="dataProfile" key={profile._id}>
                <div className="dataProfile__title">
                  <h1>{t("personalDataTitle")}</h1>
                </div>
                <div className="dataProfile__card">
                  <div className="dataProfile__card--img">
                    <img
                      className="dataProfile__img"
                      src={profile.img}
                      alt="profileimg"
                    />
                  </div>
                  <div className="dataProfile__card--info">
                    <div className="dataProfile__card--info__datos">
                      <p className="dataProfile__p">
                        {t("name")}:{" "}
                        <span>
                          {profile.name} {profile.surname}
                        </span>
                      </p>
                      <p className="dataProfile__p">
                        {t("username")}: <span>{user?.username}</span>
                      </p>
                      <p>
                        {t("email")}:<span> {user?.email}</span>
                      </p>
                      <p>
                        {t("birthdate")}: <span>{profile.birthDate}</span>
                      </p>
                      <p>
                        Rol:
                        <span> {user?.rol}</span>
                      </p>
                      <p className="dataProfile__p">
                        {t("genre")}: <span>{profile.genre}</span>
                      </p>
                    </div>
                    <div className="dataProfile__card--info__buttons">
                      <button
                        className="primary_button"
                        onClick={() => setEdit(true)}
                      >
                        {t("edit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      {edit && (
        <div className="personalData">
          <form
            className="personalData__form"
            onSubmit={handleSubmit(editProfileData)}
          >
            <img src="/assets/homeimg.jpg" alt="home" />
            <h3>{t("personalDataTitle")}</h3>
            <label htmlFor="img" className="primary_button">
              <p className="fakeButton">{t("profileImg")}</p>
            </label>
            <input type="file" name="img" id="img" {...register("img")} />

            <label>
              {t("genre")}:
              <select
                name="genre"
                id="genre"
                {...register("genre", {
                  required: true,
                })}
              >
                <option disabled selected value>
                  {t("option")}
                </option>
                <option id="genre">female</option>
                <option id="genre">male</option>
                <option id="genre">undefined</option>
              </select>
            </label>
            <label>
              {t("name")}
              <input type="name" name="name" id="name" {...register("name")} />
            </label>

            <label>
              {t("surname")}
              <input
                type="text"
                name="surname"
                id="surname"
                {...register("surname")}
              />
            </label>
            <label>
              {t("birthdate")}
              <input
                type="date"
                name="date"
                id="date"
                max={maxDate}
                {...register("birthDate")}
              />
            </label>
            <button className="primary_button" type="submit">
              {t("send")}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};
export default DatosPersonales;
