import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Cursos.scss";

// Componente reutilizable para representar un curso
const Cursos = () => {
  const { t } = useTranslation();
  return (
    <div className="cursos">
      <div className="cursos--title">
        <h1>{t("CursesTitle")}</h1>
      </div>
      <div className="autoformacion">
        <Link to="/curses/curses-online">
          <div className="autoformacion--card">
            <div className="autoformacion--card__content"></div>
            <img src="/assets/scratch.jpeg" alt="cursos" />
            <h1>{t("CursesOnlineTitle")}</h1>
          </div>
        </Link>
        <Link to="/curses/educational-books">
          <div className="autoformacion--card">
            <div className="autoformacion--card__content"></div>
            <img src="/assets/llibres.jpg" alt="cursos" />
            <h1>{t("BooksTitle")}</h1>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Cursos;
