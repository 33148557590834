import React from "react";
import { useTranslation } from "react-i18next";
import "./CursosOnline.scss";

const Curso = ({ title, image, link }) => (
  <div className="cursosOnline--one__container">
    <div className="cursosOnline--one__container__img">
      <img src={`/assets/${image}.jpeg`} alt={image} />
    </div>
    <a rel="noreferrer" target="_blank" href={link}>
      {title}
    </a>
  </div>
);

const CursosOnline = () => {
  const { t } = useTranslation();
  return (
    <div className="cursosOnline">
      <div className="cursosOnline--title">
        <h1>{t("CursesOnlineTitle")}</h1>
      </div>
      <div className="container-cursos">
        <div className="cursosOnline--one">
          <Curso
            title={t("CursesTitle1")}
            image="emocional"
            link="https://www.coursera.org/learn/teachers-social-emotional-learning?specialization=teacher-sel#outcomes"
          />
          <Curso
            title={t("CursesTitle2")}
            image="scratch"
            link="https://www.coursera.org/learn/computational-thinking-k12-educators-capstone"
          />
          <Curso
            title={t("CursesTitle3")}
            image="hoja"
            link="https://www.edx.org/es/learn/climate-change/inter-american-development-bank-climate-change-education?index=spanish_product&queryID=0404e521f43a91350517cc3f60d98284&position=14&results_level=second-level-results&term=EDUCATION+AI&objectID=course-a3c01da9-af9c-4dab-8bbf-b14bcb962b7d&campaign=Climate+Change+Education&source=edX&product_category=course&placement_url=https%3A%2F%2Fwww.edx.org%2Fes%2Fsearch"
          />
          <Curso
            title={t("CursesTitle4")}
            image="tic"
            link="https://www.coursera.org/learn/ict-primary-education"
          />
        </div>
      </div>
    </div>
  );
};

export default CursosOnline;
