import React from "react";
import "./Privacy.scss";

const Privacy = () => {
  return (
    <div className="privacy">
      <div className="privacy--container">
        <div className="privacy--container__text">
          <h3>Política de Privacidad - Mestre Cabreta</h3>
          <p>Fecha de Entrada en Vigor: 06/12/2023</p>
          <div>
            <h4>1. Información que Recopilamos</h4>
            <h5>1.1 Información del Usuario</h5>
            <p>
              Recopilamos información personal proporcionada por los usuarios al
              registrarse en la plataforma, incluyendo nombre, dirección de
              correo electrónico, y otra información necesaria para la creación
              de cuentas.
            </p>
            <h5>1.2 Información Automática</h5>
            <p>
              Podemos recopilar información automática, como direcciones IP,
              tipo de navegador, sistema operativo y otros datos técnicos al
              utilizar la plataforma.
            </p>
          </div>
          <div>
            <h4>2. Uso de la Información</h4>
            <p>Utilizamos la información recopilada para:</p>
            <ul>
              <li>Facilitar la prestación de servicios educativos.</li>
              <li>Personalizar la experiencia del usuario.</li>
              <li>Mejorar y optimizar la plataforma.</li>
              <li>Enviar comunicaciones relacionadas con el servicio.</li>
            </ul>
          </div>
          <div>
            <h4>3. Compartir Información</h4>
            <p>
              No vendemos, intercambiamos ni transferimos información personal a
              terceros sin el consentimiento del usuario, excepto en los
              siguientes casos:
            </p>
            <ul>
              <li>
                Con proveedores de servicios necesarios para operar la
                plataforma.
              </li>
              <li>En cumplimiento de requisitos legales.</li>
              <li>
                Para proteger nuestros derechos, privacidad, seguridad o
                propiedad.
              </li>
            </ul>
          </div>
          <div>
            <h4>4. Seguridad de la Información</h4>
            <p>
              Implementamos medidas de seguridad para proteger la información
              contra accesos no autorizados, pérdida, alteración o divulgación.
            </p>
          </div>
          <div>
            <h4>5. Cookies y Tecnologías Similares</h4>
            <p>
              Utilizamos cookies y tecnologías similares para mejorar la
              experiencia del usuario y recopilar información sobre el uso de la
              plataforma.
            </p>
          </div>
          <div>
            <h4>6. Enlaces a Terceros</h4>
            <p>
              Nuestra plataforma puede contener enlaces a sitios web de
              terceros. No somos responsables de las prácticas de privacidad de
              estos sitios.
            </p>
          </div>
          <div>
            <h4>7. Derechos del Usuario</h4>
            <p>
              Los usuarios tienen el derecho de acceder, corregir, eliminar u
              objetar el procesamiento de su información personal. Para ejercer
              estos derechos, contáctenos a [correo electrónico de contacto].
            </p>
          </div>
          <div>
            <h4>8. Cambios en la Política de Privacidad</h4>
            <p>
              Nos reservamos el derecho de actualizar esta Política de
              Privacidad. Los cambios serán efectivos al ser publicados en la
              plataforma.
            </p>
          </div>
          <div>
            <h4>9. Contacto</h4>
            <p>
              Para preguntas o inquietudes sobre esta Política de Privacidad,
              contáctenos a [correo electrónico de contacto].
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
