import React from "react";
import "./Terms.scss";

const Terms = () => {
  return (
    <div className="terms">
      <div className="terms--container">
        <div className="terms--container__text">
          <h3>Términos y Condiciones de Servicio - Mestre Cabreta</h3>
          <div>
            <h5>1. Aceptación de los Términos</h5>
            <p>
              Al acceder a esta plataforma de educación en línea o utilizar
              nuestros servicios, usted acepta estar legalmente vinculado por
              estos Términos y Condiciones. Si no está de acuerdo con alguno de
              los términos, no utilice esta plataforma.
            </p>
            <h5>2. Uso del Sitio</h5>
            <p>
              Usted acepta utilizar esta plataforma únicamente con fines
              educativos y de acuerdo con estos Términos y Condiciones. No se le
              permite compartir su cuenta ni violar cualquier ley aplicable.
            </p>
            <h5>3. Contenido Educativo</h5>
            <p>
              El contenido educativo proporcionado en esta plataforma es para
              uso informativo y educativo solamente. No garantizamos la
              exactitud, integridad o utilidad de la información. Los usuarios
              deben verificar la información por sí mismos.
            </p>
            <h5>4. Privacidad del Usuario</h5>
            <p>
              Respetamos la privacidad de los usuarios. Consulte nuestra
              Política de Privacidad para obtener información sobre cómo
              recopilamos, utilizamos y protegemos los datos personales de los
              usuarios.
            </p>
            <h5>5. Derechos de Propiedad Intelectual</h5>
            <p>
              Todo el contenido educativo, materiales y derechos de propiedad
              intelectual en esta plataforma son propiedad nuestra o de nuestros
              licenciantes. No está permitido copiar, reproducir o distribuir el
              contenido sin nuestro consentimiento
            </p>
            <h5>6. Responsabilidad del Usuario</h5>
            <p>
              Los usuarios son responsables de mantener la confidencialidad de
              su información de inicio de sesión y de cualquier actividad que
              ocurra bajo su cuenta. Deben notificarnos de inmediato sobre
              cualquier uso no autorizado de su cuenta.
            </p>
            <h5>7. Modificaciones y Actualizaciones</h5>
            <p>
              Nos reservamos el derecho de modificar estos Términos y
              Condiciones en cualquier momento. Las modificaciones serán
              efectivas inmediatamente después de su publicación en la
              plataforma. Es responsabilidad del usuario revisar periódicamente
              los Términos y Condiciones.
            </p>
            <h5>8. Limitación de Responsabilidad</h5>
            <p>
              No seremos responsables de cualquier pérdida o daño directo,
              indirecto, incidental, especial o consecuente resultante del uso
              de esta plataforma o del contenido educativo proporcionado.
            </p>
            <h5>9. Ley Aplicable</h5>
            <p>
              Estos Términos y Condiciones se regirán e interpretarán de acuerdo
              con las leyes del [país o jurisdicción correspondiente].
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
