import React from "react";
import "./LibrosEducativos.scss";
import { useTranslation } from "react-i18next";

const LibrosEducativos = () => {
  const { t } = useTranslation();
  return (
    <div className="books">
      <div className="books--title">
        <h1>{t("BooksTitle")}</h1>
      </div>
      <div className="books--container">
        <div className="books--container__img">
          <img src="/assets/llibres.jpg" alt="llibres" />
        </div>
        <div className="books--container__text">
          <p>{t("Llibresp1")}</p>
          <p>{t("Llibresp2")}</p>
          <p>{t("Llibresp3")}</p>
          <p>{t("Llibresp4")}</p>
          <p>{t("Llibresp5")}</p>
        </div>
      </div>
    </div>
  );
};

export default LibrosEducativos;
