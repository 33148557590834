import React from "react";
import "../../Pages/Home/Home.scss";

const SocialMediaLink = ({ platform, username, icon, link }) => (
  <a href={link} rel="noreferrer" target="_blank">
    <div className="social">
      <div className="social--img">
        <img src={`/assets/${icon}.png`} alt={platform} />
      </div>
      <div className="social--links">
        <h2>{platform}</h2>
        <a href={link} rel="noreferrer" target="_blank">
          @{username}
        </a>
      </div>
    </div>
  </a>
);

export default SocialMediaLink;
