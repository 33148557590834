import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Recurses.scss";

const Recurso = ({ title, videoId, image, link, descripcion, textoLink }) => (
  <div className="recurses--more__container">
    <div className="recurses--more__container__left">
      {videoId ? (
        <iframe
          width="464"
          height="261"
          frameBorder="0"
          src={`https://www.youtube.com/embed/${videoId}?rel=0&controls=0&showinfo=0&autoplay=0`}
          title={title}
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      ) : (
        <img src={`/assets/${image}.jpeg`} alt={image} />
      )}
    </div>
    <div className="recurses--more__container__right">
      <h1>{title}</h1>
      <p>{descripcion}</p>
      {link && <p>{link === "video" ? "Descripción del video." : ""}</p>}
      {link && (
        <Link activeClassName="active" to={`/recurses/${link}`}>
          {textoLink}
        </Link>
      )}
    </div>
  </div>
);

const Recurses = () => {
  const { t } = useTranslation();
  return (
    <div className="recurses">
      <div className="recurses--top">
        <h1>{t("titleRecurses")}</h1>
      </div>
      <div className="recurses--more">
        <Recurso
          title={t("Recurso1title")}
          videoId="uoVN6VeGTg4"
          link="minecraft-educatiu-i-soberania-alimentaria"
          descripcion={t("Recurso1p")}
          textoLink={t("linkRecuso1")}
        />
        <Recurso
          title={t("Recurso2title")}
          image="cubo" /* siempre en jpeg */
          descripcion={t("Recurso2p")}
        />
      </div>
    </div>
  );
};

export default Recurses;
