import React from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";

const Autoformacio = () => {
  const { t } = useTranslation();
  return (
    <div className="lng dropdown">
      <button class="dropbtnAutoformacio">
        <NavLink activeClassName="active" to="/curses">
          {t("CursesNav")}
        </NavLink>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="m11.3 14.3l-2.6-2.6q-.475-.475-.212-1.087T9.425 10h5.15q.675 0 .938.613T15.3 11.7l-2.6 2.6q-.15.15-.325.225T12 14.6q-.2 0-.375-.075T11.3 14.3Z" />
        </svg>
      </button>
      <div class="dropdown-content">
        <Link to="/curses/educational-books">{t("BooksTitle")}</Link>
        <Link to="/curses/curses-online">{t("CursesOnlineTitle")}</Link>
      </div>
    </div>
  );
};

export default Autoformacio;
