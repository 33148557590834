import React from "react";
import { useTranslation } from "react-i18next";
import SocialMediaLink from "../../Components/SocialMediaLink/SocialMediaLink";
import "./Home.scss";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="home" id="Home">
      <div className="home--principal">
        <div className="home--principal__title">
          <h1>MESTRE CABRETA</h1>
          <h2>{t("hometitle")}</h2>
          <h3>{t("hometitle2")}</h3>
        </div>
      </div>
      <div className="home--container">
        <div className="home--container__about">
          <h1>ABOUT ME</h1>
          <div className="home--container__about--info">
            <div>
              <img src="/assets/Firenze.jpg" alt="pictureferran" />
            </div>
            <div className="home--container__about--info__text">
              <p>{t("aboutmep1")}</p>
              <p>{t("aboutmep2")}</p>
              <p>{t("aboutmep3")}</p>
            </div>
          </div>
        </div>
        <div className="home--container__more">
          <h1>{t("titleMedia")}</h1>
          <div className="home--container__more--social">
            <SocialMediaLink
              platform="TIKTOK"
              username="mestrecabreta"
              icon="tiktok" /* icon siempre en png  */
              link="https://tiktok.com/@mestrecabreta"
            />
            <SocialMediaLink
              platform="INSTAGRAM"
              username="mestrecabreta"
              icon="insta" /* icon siempre en png  */
              link="https://instagram.com/mestrecabreta"
            />
            <SocialMediaLink
              platform="YOUTUBE"
              username="mestrecabreta"
              icon="youtube" /* icon siempre en png  */
              link="https://youtube.com/@mestrecabreta"
            />
          </div>
        </div>
      </div>

      <div className="home--footer">
        <h3>{t("hometitleFooter")}</h3>
        <p>{t("homepFooter")}</p>
      </div>
    </div>
  );
};

export default Home;
