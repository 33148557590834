import { Analytics } from "@vercel/analytics/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import NotFound from "./Components/NotFound/NotFound";
import Cursos from "./Pages/Cursos/Cursos";
import CursosOnline from "./Pages/Cursos/CursosOnline/CursosOnline";
import LibrosEducativos from "./Pages/Cursos/LibrosEducativos/LibrosEducativos";
import Home from "./Pages/Home/Home";
import Profile from "./Pages/Profile/Profile";
import Minecraft from "./Pages/Recurses/Minecraft/Minecraft";
import Recurses from "./Pages/Recurses/Recurses";
import Privacy from "./Pages/Terms/Privacy";
import Terms from "./Pages/Terms/Terms";
import { checkSession } from "./Redux/auth/auth.actions";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const token = localStorage.getItem("token");
  useEffect(() => {
    token && dispatch(checkSession(token, navigate));
  }, [token, dispatch, navigate]);

  useEffect(() => {
    const theUser = localStorage.getItem("user");

    if (theUser && !theUser.includes("undefined")) {
      setUser(JSON.parse(theUser));
    }
  }, []);

  return (
    <div className="App">
      <Analytics />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/recurses" element={<Recurses />}></Route>
        <Route path="/curses" element={<Cursos />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/terms-of-services" element={<Terms />} />
        <Route path="/privacy-notice" element={<Privacy />} />
        <Route
          path="/recurses/minecraft-educatiu-i-soberania-alimentaria"
          element={<Minecraft />}
        ></Route>
        <Route path="/curses/curses-online" element={<CursosOnline />} />
        <Route
          path="/curses/educational-books"
          element={<LibrosEducativos />}
        />
        <Route path="*" Component={NotFound} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
