import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/auth/auth.actions";
import i18n from "../../i18n/i18n";
import Autoformacio from "../Autoformacio/Autoformacio";
import Lng from "../Lng/Lng";
import "./Navbar.scss";

const Navbar = () => {
  const { t } = useTranslation();
  const [hamburger, setHamburger] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [colorChange, setColorChange] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const popupRef = useRef(null); // Referencia al elemento del popup

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const hamburgerMenu = () => {
    setHamburger(!hamburger);
  };

  const toggleMenuMobile = () => {
    setIsPopupOpen2(!isPopupOpen2);
    /* setIsBackgroundBlocked(!isBackgroundBlocked); */
  };

  const changeNavbarColor = () => {
    setColorChange(window.scrollY >= 80);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const pageUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const navLinks = [
    { to: "/", text: t("homeNav") },
    { to: "/recurses", text: t("RecursesNav") },
  ];

  const { user, token } = useSelector((state) => state.auth);
  const { profiles } = useSelector((state) => state.profiles);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clearProfileImageFromLocalStorage = () => {
    localStorage.removeItem("profileImage");
  };

  const logOutButton = () => {
    dispatch(logoutUser(navigate));
    togglePopup();
    clearProfileImageFromLocalStorage();
  };

  const logOutButtonMobile = () => {
    dispatch(logoutUser(navigate));
    clearProfileImageFromLocalStorage();
  };

  const [dropdownOpenLng, setDropdownOpenLng] = useState(false);

  const toggleDropdownLng = () => {
    setDropdownOpenLng(!dropdownOpenLng);
  };

  const [dropdownOpenAuto, setDropdownOpenAuto] = useState(false);

  const toggleDropdownAuto = () => {
    setDropdownOpenAuto(!dropdownOpenAuto);
  };

  const lng = [
    { text: "Valenciano", value: "va" },
    { text: "Español", value: "es" },
    { text: "English", value: "en" },
    { text: "Italiano", value: "it" },
  ];

  const recurses = [
    { text: t("BooksTitle"), to: "/educational-books" },
    { text: t("CursesOnlineTitle"), to: "/curses-online" },
    { text: t("CursesNav"), to: "/curses" },
  ];

  return (
    <div className={`navbar ${colorChange ? "colorChange" : ""}`}>
      <nav>
        <div className="navbar--nav">
          <div className="navbar--nav--title">
            <NavLink activeClassName="active" to="/">
              <img src="/assets/homeimg.jpg" alt="logo" />
            </NavLink>
            <h1 onClick={pageUp}>Mestre Cabreta</h1>
          </div>

          <div className="navbar--nav--links">
            {navLinks.map((link, index) => (
              <NavLink key={index} to={link.to} activeClassName="active">
                {link.text}
              </NavLink>
            ))}
            <Autoformacio />
            <Lng />
            {!user && (
              <NavLink to="/profile" aria-label="profile">
                <svg fill="none" viewBox="0 0 24 24" height="24px" width="24px">
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M16 9a4 4 0 11-8 0 4 4 0 018 0zm-2 0a2 2 0 11-4 0 2 2 0 014 0z"
                    clipRule="evenodd"
                  />
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zM3 12c0 2.09.713 4.014 1.908 5.542A8.986 8.986 0 0112.065 14a8.984 8.984 0 017.092 3.458A9 9 0 103 12zm9 9a8.963 8.963 0 01-5.672-2.012A6.992 6.992 0 0112.065 16a6.991 6.991 0 015.689 2.92A8.964 8.964 0 0112 21z"
                    clipRule="evenodd"
                  />
                </svg>
              </NavLink>
            )}
            {user && (
              <NavLink
                onClick={togglePopup}
                className="userName-wrapper"
                aria-label="profile"
              >
                {localStorage.getItem("profileImage") ? (
                  <img
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                    src={localStorage.getItem("profileImage")}
                    alt="Profile"
                    width="32px"
                    height="32px"
                  />
                ) : (
                  <svg
                    fill="none"
                    viewBox="0 0 24 24"
                    height="25px"
                    width="25px"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M16 9a4 4 0 11-8 0 4 4 0 018 0zm-2 0a2 2 0 11-4 0 2 2 0 014 0z"
                      clipRule="evenodd"
                    />
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zM3 12c0 2.09.713 4.014 1.908 5.542A8.986 8.986 0 0112.065 14a8.984 8.984 0 017.092 3.458A9 9 0 103 12zm9 9a8.963 8.963 0 01-5.672-2.012A6.992 6.992 0 0112.065 16a6.991 6.991 0 015.689 2.92A8.964 8.964 0 0112 21z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </NavLink>
            )}
            {isPopupOpen && (
              <div ref={popupRef} className="popup-overlay">
                <div className="popup-content">
                  <div className="popup-content__profile">
                    <Link to="/profile">
                      <img
                        style={{ borderRadius: "50%", objectFit: "cover" }}
                        src={localStorage.getItem("profileImage")}
                        alt="Profile"
                        width="32px"
                        height="32px"
                      />
                      <p>{user.username}</p>
                    </Link>
                  </div>
                  <Link to="/profile" onClick={togglePopup}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon icon-tabler icons-tabler-outline icon-tabler-user"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                      <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    </svg>
                    {t("ProfileNav")}
                  </Link>
                  <Link onClick={logOutButton} style={{ color: "red" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon icon-tabler icons-tabler-outline icon-tabler-logout"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                      <path d="M9 12h12l-3 -3" />
                      <path d="M18 15l3 -3" />
                    </svg>
                    {t("Signout")}
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="menu" onClick={toggleMenuMobile}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-menu-2"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 6l16 0" />
              <path d="M4 12l16 0" />
              <path d="M4 18l16 0" />
            </svg>
          </div>
        </div>
      </nav>

      {isPopupOpen2 && (
        <div className="mobilemenu">
          <div className="mobilemenu--content">
            <div className="mobilemenu--content__top">
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <img width="45px" src="/assets/homeimg.jpg" alt="logo" />
                <h1 onClick={pageUp}>Mestre Cabreta</h1>
              </div>
              <div>
                <svg
                  onClick={toggleMenuMobile}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-x"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M18 6l-12 12" />
                  <path d="M6 6l12 12" />
                </svg>
              </div>
            </div>

            <div className="mobilemenu--content__links">
              <div className="mobilemenu--content__links--link">
                {user ? (
                  <NavLink to="/profile" onClick={toggleMenuMobile}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-user"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                      <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    </svg>
                    {t("ProfileNav")}
                  </NavLink>
                ) : (
                  <NavLink to="/profile" onClick={toggleMenuMobile}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-login"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M15 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                      <path d="M21 12h-13l3 -3" />
                      <path d="M11 15l-3 -3" />
                    </svg>{" "}
                    {t("Login")}
                  </NavLink>
                )}

                <NavLink onClick={toggleMenuMobile} to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-home"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                  </svg>
                  {t("homeNav")}
                </NavLink>
                <NavLink onClick={toggleMenuMobile} to="/recurses">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-archive"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                    <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                    <path d="M10 12l4 0" />
                  </svg>
                  {t("RecursesNav")}
                </NavLink>
              </div>

              <div
                className="mobilemenu--content__links--lng"
                onClick={toggleDropdownAuto}
              >
                <div className="mobilemenu--content__links--lng__svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-books"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
                    <path d="M9 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z" />
                    <path d="M5 8h4" />
                    <path d="M9 16h4" />
                    <path d="M13.803 4.56l2.184 -.53c.562 -.135 1.133 .19 1.282 .732l3.695 13.418a1.02 1.02 0 0 1 -.634 1.219l-.133 .041l-2.184 .53c-.562 .135 -1.133 -.19 -1.282 -.732l-3.695 -13.418a1.02 1.02 0 0 1 .634 -1.219l.133 -.041z" />
                    <path d="M14 9l4 -1" />
                    <path d="M16 16l3.923 -.98" />
                  </svg>
                  <p>{t("CursesNav")}</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-chevron-down"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke-width="1"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M6 9l6 6l6 -6" />
                  </svg>
                </div>

                {dropdownOpenAuto && (
                  <div className="mobilemenu--content__links--lng__toggle">
                    {recurses.map((item) => (
                      <button onClick={toggleMenuMobile}>
                        {item.text}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-arrow-up-right"
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M17 7l-10 10" />
                          <path d="M8 7l9 0l0 9" />
                        </svg>
                      </button>
                    ))}
                  </div>
                )}
              </div>

              <div
                className="mobilemenu--content__links--lng"
                onClick={toggleDropdownLng}
              >
                <div className="mobilemenu--content__links--lng__svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-language"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 5h7" />
                    <path d="M9 3v2c0 4.418 -2.239 8 -5 8" />
                    <path d="M5 9c0 2.144 2.952 3.908 6.7 4" />
                    <path d="M12 20l4 -9l4 9" />
                    <path d="M19.1 18h-6.2" />
                  </svg>
                  <p>{t("Language")}</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-chevron-down"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke-width="1"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M6 9l6 6l6 -6" />
                  </svg>
                </div>

                {dropdownOpenLng && (
                  <div className="mobilemenu--content__links--lng__toggle">
                    {lng.map((item) => (
                      <button
                        onClick={() => {
                          changeLanguage(item.value);
                        }}
                      >
                        {item.text}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-arrow-up-right"
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M17 7l-10 10" />
                          <path d="M8 7l9 0l0 9" />
                        </svg>
                      </button>
                    ))}
                  </div>
                )}
              </div>
              {user && (
                <div
                  className="mobilemenu--content__links--logout"
                  onClick={toggleMenuMobile}
                >
                  <NavLink onClick={logOutButtonMobile}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon icon-tabler icons-tabler-outline icon-tabler-logout"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                      <path d="M9 12h12l-3 -3" />
                      <path d="M18 15l3 -3" />
                    </svg>
                    {t("Signout")}
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
