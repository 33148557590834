import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Pagination from "../../../Components/Pagination/Pagination";
import "./Minecraft.scss";

const Minecraft = () => {
  const { t } = useTranslation();
  const { user, token } = useSelector((state) => state.auth);

  const [currentPage, setCurrentPage] = useState(1);
  const [commentsPerPage] = useState(5); // Número de comentarios por página

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setComment((prevComment) => ({
      ...prevComment,
      [name]: value,
    }));
  };

  const handleSubmitComment = async () => {
    try {
      const response = await fetch(
        "https://mestrecabreta-api.vercel.app/comments/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(comment),
        }
      );

      if (response.ok) {
        console.log("Comentario enviado exitosamente");
        fetchData(); // Actualizar comentarios después de enviar el comentario
      } else {
        console.error("Error al enviar el comentario");
      }
    } catch (error) {
      console.error("Error de red:", error);
    }
  };

  const handleDeleteComment = async (id) => {
    try {
      const response = await axios.delete(
        `https://mestrecabreta-api.vercel.app/comments/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Comentario eliminado exitosamente");
        fetchData(); // Actualizar comentarios después de eliminar el comentario
      } else {
        console.error("Error al eliminar el comentario");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error al eliminar el comentario:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://mestrecabreta-api.vercel.app/comments`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const commentsData = response.data.filter(
        (item) => item.url === "minecraft"
      );
      setData(commentsData.reverse());
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const indexOfLastComment = currentPage * commentsPerPage;
  const indexOfFirstComment = indexOfLastComment - commentsPerPage;
  const currentComments =
    data && data.slice(indexOfFirstComment, indexOfLastComment);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [comment, setComment] = useState({
    name: "",
    content: "",
    date: new Date().toISOString().split("T")[0],
    url: "minecraft",
  });
  return (
    <div className="minecraft">
      <div className="minecraft--title">
        <h1>{t("MinecraftTitle")}</h1>
      </div>
      <div className="minecraft--more">
        <div className="minecraft--more__video">
          <div>
            <iframe
              style={{ borderRadius: "10px" }}
              width="464"
              height="261"
              frameBorder="0"
              src="https://www.youtube.com/embed/uoVN6VeGTg4?rel=0&controls=0&showinfo=1&autoplay=0"
              title="Minecraft Video"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          </div>
          <div className="parrafo">
            <p>{t("Minecraftp1")}</p>
            <p>{t("Minecraftp2")}</p>
          </div>
        </div>
        <div className="minecraft--more__sesions">
          <p>{t("Minecraftp3")}</p>

          <div className="minecraft--more__sesions__container">
            <h2>{t("titleSesion")}</h2>
            <p>
              <span>{t("sesion1")}</span>
            </p>
            <p>{t("sesion1p1")}</p>
            <p>{t("sesion1p2")}</p>
            <p>{t("sesion1p3")}</p>
            <ul>
              <li>
                <span>{t("sesiongroup1")} </span>
                {t("sesiongroup1p")}
              </li>
              <li>
                <span>{t("sesiongroup2")} </span>
                {t("sesiongroup2p")}
              </li>
              <li>
                <span>{t("sesiongroup3")} </span>
                {t("sesiongroup3p")}
              </li>
              <li>
                <span>{t("sesiongroup4")} </span>
                {t("sesiongroup4p")}
              </li>
              <li>
                <span>{t("sesiongroup5")} </span>
                {t("sesiongroup5p")}
              </li>
            </ul>
          </div>
          <div className="minecraft--more__video__sesions">
            <p>
              <span>{t("sesion2")}</span>
            </p>
            <p>{t("sesion2p1")}</p>
            <ul>
              <li>{t("sesion2li1")}</li>
              <li>{t("sesion2li2")}</li>
            </ul>
          </div>
          <div className="minecraft--more__video__sesions">
            <p>
              <span>{t("sesion3")}</span>
            </p>
            <ul>
              <li>{t("sesion3p1")}</li>
            </ul>
          </div>
          <div className="minecraft--more__video__sesions">
            <h3>{t("MinecraftTitleFooter")}</h3>
            <p>{t("Minecraftp1Footer")}</p>
            <p>{t("Minecrafp2Footer")}</p>
          </div>
        </div>
        <div className="minecraft--comments">
          <h2>{t("Comments")}</h2>
          {user && (
            <div className="minecraft--comments__inputs">
              <input
                type="text"
                name="name"
                value={comment.name}
                onChange={handleInputChange}
                placeholder="Your Name"
              />
              <textarea
                type="text"
                name="content"
                value={comment.content}
                onChange={handleInputChange}
                placeholder="Your Comment"
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="img"
                value={comment.img}
                onChange={handleInputChange}
                disabled
              />
              <input
                style={{ display: "none" }}
                type="date"
                name="date"
                value={comment.date}
                onChange={handleInputChange}
                placeholder="Fecha"
                disabled
              />
              <input
                style={{ display: "none" }}
                type="text"
                name="url"
                value={comment.url}
                onChange={handleInputChange}
                disabled
              />
              <button onClick={handleSubmitComment}>{t("send")}</button>
            </div>
          )}
          {!user && (
            <p className="logincomments">
              <a href="/profile">{t("ComemntsLogin")}</a> {t("CommentsText")}
            </p>
          )}

          <div className="comments--container">
            {loading && <p>Cargando comentarios...</p>}
            {error && <p>Error al cargar comentarios: {error.message}</p>}
            {currentComments &&
              currentComments.reverse().map((item) => (
                <div className="comments--container__comment" key={item.id}>
                  <div className="comments--container__comment--info">
                    <img src="/assets/userimg.png" alt="Userimg" />
                    <div>
                      <h3>{item.name}</h3>
                      <p>{item.content}</p>
                    </div>
                  </div>
                  {user?.rol === "admin" && (
                    <div
                      className="comments--container__comment--delete"
                      onClick={() => handleDeleteComment(item._id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-trash"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 7l16 0" />
                        <path d="M10 11l0 6" />
                        <path d="M14 11l0 6" />
                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                      </svg>
                    </div>
                  )}
                </div>
              ))}
            {currentComments && currentComments.length === 0 && (
              <p>No hay comentarios</p>
            )}
          </div>
          <Pagination
            itemsPerPage={commentsPerPage}
            totalItems={data && data.length}
            paginate={paginate}
          />
        </div>
      </div>
    </div>
  );
};

export default Minecraft;
